/**
 * Created by john on 1/10/16.
 */
'use strict';

/* exported
 sessionSrv
 */

function getSession($localStorage){
  var session = $localStorage.session;
  return session;
}

function sessionSrv($localStorage) {
  /* jshint validthis: true */
  this.create = function (user, token) {
    $localStorage.session = {
      user: user,
      token: token
    };
  };

  /* jshint validthis: true */
  this.destroy = function() {
    delete window.user;
    delete $localStorage.session;
  };

  this.getRole = function() {
    var session = getSession($localStorage);

    return ((session || {}).user || {}).role;
  };

  this.getId = function() {
    var session = getSession($localStorage);

    return ((session || {}).user || {})._id;
  };

  this.getUser = function() {
    var session = getSession($localStorage);
    return (session || {}).user;
  };

  this.getAccessToken = function() {
    var session = getSession($localStorage);

    // TODO: See what is jscs??
    // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
    return ((session || {}).token || {}).access_token; /* jshint ignore:line */
    // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
  };
}

'use strict';

/* exported
 declineSrv
 */

function declineSrv() {
  /* jshint validthis: true */
  this.formatReason = function (reason, text) {
    var result;
    switch (reason) {
        case 'fully_booked':
          result =  'Fully Booked';
          break;
        case 'departure_does_not_exists':
          result = 'Departure does not exists';
          break;
        case 'departure_cancelled':
          result = 'Departure Cancelled';
          break;
        case 'double_booking':
          result = 'Double Booking';
          break;
        case 'out_of_credit':
          result = 'Out of Credits';
          break;
      case 'not_approved_on_time':
        result = 'Not Approved On Time';
        break;
      case 'schedule_is_not_open_yet':
        result = 'Schedule is not open yet';
        break;
      case 'missing_info_from_client':
        result = 'Missing information from client';
        break;
      case 'pickup_location_not_supported':
        result = 'Pickup location is not supported';
        break;
      case 'not_enough_passengers':
        result = 'Not Enough Passengers';
        break;
      case 'supplier_unresponsive':
        result = 'Supplier Unresponsive';
        break;
      case 'rate_difference':
        result = 'Rate Difference';
        break;
      case 'other':
        result = 'Other';
        break;
        default:
          result = reason;
      }
      if (text){
        return result + ': ' + text;
      } else {
        return result;
      }
  };
}

'use strict';

/* exported
 productFormatterService
 */


function productFormatterService() {
  this.formatProduct = function(product, fromId, toId) {
    let direction = 'fromAtoB';
    if (product.stationB.city._id === fromId && product.stationA.city._id === toId){
        direction = 'fromBtoA';
    }
    const companyName = product.company.name;
    const from = direction === "fromAtoB" ? product.stationA : product.stationB;
    const to = direction === "fromAtoB" ? product.stationB : product.stationA;
    const events = direction === "fromAtoB" ? product.schedule.aToBEvents : product.schedule.bToAEvents;
    const inboundEvents = direction === "fromAtoB" ? product.schedule.bToAEvents : product.schedule.aToBEvents;
    const manualBookings = product.manualBookings || {
        operations: false,
        support: false,
    };
    
    const departureTimes = events.map(event => ({
      time: moment.utc(moment.duration(event.start).asMilliseconds()).format('HH:mm'),
      fullDates: event.full
    }))

    return Object.assign(
      product, 
      { 
        companyName, 
        direction, 
        from, 
        to, 
        events, 
        inboundEvents, 
        manualBookings, 
        departureTimes,
        price: product.defaultPrice.amount,
        currency: product.defaultPrice.currency
      }
    );
  };

  this.formatOnlineProduct = function(product) {
    let onlineProduct = _.get(product, 'legs.0');
    const company = {
        name: onlineProduct.supplier.name,
        _id: onlineProduct.supplier.id,
    };
    const supplier = {
        id: onlineProduct.supplierId,
        name: onlineProduct.supplierName
    };

    const departureTimes = onlineProduct.journeys.map(journey => ({
      time : moment(journey.departure.date).format('HH:mm')
    }))
    
    return Object.assign(
      onlineProduct, 
      { 
        transportReference: product._id, 
        company, 
        supplier, 
        companyName: onlineProduct.companyName,
        type: onlineProduct.type,
        lineClass: onlineProduct.lineClass,
        departureTimes,
        price: onlineProduct.price.amount,
        currency: onlineProduct.price.currency
      });
  };

  this.isDepartureAvailable = function(departureTimes, date) {
    const hasDepartureTimes = departureTimes && departureTimes.length;
    if (!hasDepartureTimes) return false;
  
    const desiredDate = moment(date).format('YYYY-MM-DD');
  
    return departureTimes.some(departureTime => 
      !departureTime.fullDates || !departureTime.fullDates.includes(desiredDate)
    );
  };
}

/**
 * Created by john on 1/10/16.
 */
'use strict';

/* exported
 authInterceptor
 */

function authInterceptor($rootScope, $q, AUTH_EVENTS, Session) {
  return {
    responseError: function (response) {
      $rootScope.$broadcast({
        401: AUTH_EVENTS.notAuthenticated,
        403: AUTH_EVENTS.notAuthorized
      }[response.status], response);
      if(response.data) {
        if(response.data.error === 'LockedOut') {
          return $q.reject(response.data.error); 
        }
        if(response.data.message === 'Password reset token is invalid or has expired.') {
          return $q.reject(response.data.message); 
        }
      }
      if (!response.config.url.match(/\/auth\/login$/)) {
        return $q.reject(response);
      }
    },
    request: function(config) {
      var accessToken = Session.getAccessToken();
      config.headers = config.headers || {};

      // TODO: Restrict to 80days app domain to avoid sending the token to other servers
      if (accessToken) {
        config.headers.Authorization = 'Bearer ' + accessToken;
      }

      return config || $q.when(config);
    }
  };
}
